import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Link, injectIntl } from "gatsby-plugin-intl"
import { genderReverse } from "../../../utils/lyneUphelpers"

const LyneGenderSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
`

const LyneGenderChooseText = styled.p`
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;
`

const LyneNoGenderText = styled.p`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  color: ${({ error }) => (error ? "#EF2C2C" : "#262626")};
  max-width: 420px;
`

const LyneGenderSectionBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

const LyneGenderBox = styled(Link)`
  display: flex;
  width: 100%;
  max-width: 170px;
  padding: 3px;
  cursor: pointer;
  border: ${({ error, active }) =>
    error
      ? "1px solid #EF2C2C"
      : active
      ? "2px solid #5db9bc"
      : "0px solid #5db9bc"};
  border-radius: ${({ error, active }) => (error || active ? "5px" : "0px")};
  margin: 0px 3px;

  :hover {
    border: 2px solid #5db9bc;
    border-radius: 5px;
  }

  @media (max-width: 600px) {
    max-width: calc(50% - 6px);
  }
`

const LyneGenderBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ active }) => (active ? "#D9F0F1" : "inherit")};
  border: ${({ active }) =>
    active ? "0.5px solid #fff" : "0.5px solid #262626"};
  border-radius: 5px;
  font-weight: ${({ active }) => (active ? "bold" : "500")};
  overflow: hidden;
`

const LyneGenderTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 9px 5px;

  @media (max-width: 360px) {
    padding: 9px 6px 5px;
  }
`

const LyneGenderText = styled.p`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;

  @media (max-width: 360px) {
    font-size: 13.5px;
  }
`

const LyneGenderImage = styled.img`
  display: flex;
  object-fit: contain;
  margin: 0px;
  width: 100%;
    aspect-ratio: 16 / 7;
`

const SolutionGenderRedirectSection = ({
  intl,
  data,
  gender,
  error,
  id,
}) => {
  const [activeIndex, setactiveIndex] = useState(null)
  const generateRedirectionUrl = (index) => {
    if (typeof index === 'undefined' || index === null)
      return '';

    if (index === 0)
      return '/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudré&Size=&Offer=Seul'
    else if (index === 1)
      return '/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul'
    else
      return '/shop/solutions-textiles/'
  }
  useEffect(() => {
    if (gender) setactiveIndex(data.indexOf(genderReverse[intl.locale][gender]))
    else setactiveIndex(null)
  }, [gender])
  return (
    data && (
      <LyneGenderSectionWrapper id={id}>
        <LyneGenderChooseText
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "lyne_selected_gender_text",
            }),
          }}
        />
        <LyneGenderSectionBox>
          {data.map((item, index) => (
            <LyneGenderBox
              to={generateRedirectionUrl(index)}
              key={index.toString()}
              active={activeIndex === index}
              error={error}
            >
              <LyneGenderBoxWrapper active={activeIndex === index}>
                <LyneGenderTextBox>
                  <LyneGenderText
                    dangerouslySetInnerHTML={{
                      __html: index == 0 ? "Femme >" : "Homme >",
                    }}
                  />
                </LyneGenderTextBox>
                {!gender && (
                  <LyneGenderImage
                    className="lazyload"
                    data-src={
                      index == 0
                        ? "https://static.percko.com/uploads/35735400-e2d8-4633-ab33-73482492cfce.jpg"
                        : "https://static.percko.com/uploads/a08c5ffb-5493-4c13-a8e9-4698e11ad684.jpg"
                    }
                  />
                )}
              </LyneGenderBoxWrapper>
            </LyneGenderBox>
          ))}
        </LyneGenderSectionBox>
        {!gender && (
          <LyneNoGenderText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "lyne_no_gender_text",
              }),
            }}
            error={error}
          />
        )}
      </LyneGenderSectionWrapper>
    )
  )
}

export default injectIntl(SolutionGenderRedirectSection)
